/*! -----------------------------------------------------------------------------------

    Template Name: Zeta Admin
    Template URI: http://admin.pixelstrap.com/zeta/theme
    Description: This is Admin theme
    Author: Pixelstrap
    Author URI: https://themeforest.net/user/pixelstrap

-----------------------------------------------------------------------------------
 */
// *** utils ***
// @import "utils/variables";

:root {
  --theme-deafult: #6362e7;
  --theme-secondary: #ffc500;
  --theme-success: #2a856c;
}
@import "vendors/whether-icon";
// @import "vendors/font-awesome/variables";
@import "./vendors/chartist/chartist-settings";
@import "./vendors/chartist/chartist";
@import "vendors/flag-icon";
@import "vendors/icofont"; 
@import "vendors/themify";
@import "vendors/font-awesome.scss";
@import "../scss/vendors/todo.scss";
@import "../scss/vendors/scrollable";
@import "vendors/sticky";

// @import "vendors/photoswipe.scss";ERROR Error: Uncaught (in promise): ReferenceError: global is not defined

// ngx-mat-timepicker
// @import "~@angular/material/prebuilt-themes/indigo-pink.css";



// ngx-toast
// @import "../../../node_modules/ngx-toastr/toastr.css";
// @import "../../../node_modules/ngx-toastr/toastr-bs4-alert.scss";

// Ngx-Tabset
// @import "~ngx-tabset/ngx-tabset";

// *** base ***
@import "base/reset";
@import "base/typography"; 



//  *** components *** 
@import "components/scrollbar";
@import "components/according";
@import "components/alert.scss";
@import "components/avatars.scss";
@import "components/angular-plugins.scss";
@import "components/badge.scss";
@import "components/bookmark.scss";
@import "components/breadcrumb.scss";
@import "components/buttons.scss";
@import "components/card.scss";
@import "components/color"; 
@import "components/datatable.scss";
@import "components/datepicker.scss";
@import "components/dropdown.scss";
@import "components/form-input.scss";
@import "components/form-wizard";
@import "components/forms.scss";
@import "components/icons.scss";
@import "components/list.scss";
@import "components/loader.scss";
@import "components/popover.scss";
@import "components/print.scss";
@import "components/radio.scss";
@import "components/ribbon.scss";
@import "components/switch.scss";
@import "components/select2.scss";
@import "components/tab.scss";
@import "components/table.scss";
@import "components/touchspin.scss";
@import "components/tour.scss";
@import "components/tree.scss";
@import "components/typeahead-search.scss";

//	*** pages ***

@import "pages/blog.scss";
@import "pages/bookmark-app.scss";
@import "pages/cart.scss";
@import "pages/chart.scss";
@import "pages/chat.scss";
@import "pages/checkout.scss";
@import "pages/comingsoon.scss";
@import "pages/contacts.scss";
@import "pages/dashboard_2.scss";
@import "pages/dashboard_default.scss";
@import "pages/_crypto-dashboard";
@import "pages/ecommerce.scss";
@import "pages/email-application.scss";
@import "pages/errorpage.scss";
@import "pages/faq.scss";
@import "pages/file.scss";
@import "pages/gallery.scss";
@import "pages/job-search.scss";
@import "pages/knowledgebase.scss";
@import "pages/language.scss";
@import "pages/learning.scss";
@import "pages/login.scss";
@import "pages/megaoption.scss";
@import "pages/order-history.scss";
@import "pages/page.scss";
@import "pages/pricing.scss";
@import "pages/progress.scss";
@import "pages/projectlist.scss";
@import "pages/social-app.scss";
@import "pages/task.scss";
@import "pages/invoice";
@import "pages/timeline-v.scss";
@import "pages/timeliny.scss";
@import "pages/user-profile.scss";
@import "pages/toast.scss";
// @import "pages/calender-basic";
@import "pages/steps";


/* ---------------------
	*** themes ***
-----------------------*/
@import "./themes/dark";
@import "./themes/theme-customizer";

// *** layout ***

@import "layout/footer.scss";
@import "layout/grid.scss";
@import "layout/header.scss";
@import "layout/navs.scss";
@import "layout/search.scss";
@import "layout/sidebar";
@import "themes/responsive";
@import "layout/rtl.scss";
@import "layout/box-layout.scss";