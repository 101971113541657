/**=====================
    49. Badge CSS start
==========================**/
.badge {
  padding: $badge-padding;
  font-family: $font-roboto, $font-serif;
  font-weight: 500;
  & + .badge {
    margin-left: 5px;
  }
  svg {
    width: $badge-svg-size;
    height: $badge-svg-size;
    padding-top: 3px;
  }
}
.pill-icon {
  .badge {
    padding: 4px 5px;
    &:hover {
      color: $white;
    }
    svg {
      height: 13px;
      width: 14px;
    }
  }
}
.digits{
  .badge{
    padding: 5px 7px 4px;
  }
}
@each $badge-light-name, $badge-light-color in (primary, $primary-color),
  (secondary, $secondary-color), (success, $success-color),
  (danger, $danger-color), (info, $info-color), (light, $light-color),
  (dark, $dark-color), (warning, $warning-color), (theme-light, $light-1)
{
  .badge-light-#{$badge-light-name} {
    background-color: rgba($badge-light-color, 0.2);
    color: $badge-light-color;
  }
}
.badge-light-primary {
  background-color: rgba($primary-color, 0.1);
}
.badge-light-success {
  background-color: rgba($success-color, 0.2);
}
.badge-light-theme-light {
  background-color: $light-2;
  color: $light-1;
}
// flat badge loop css
@each $pill-badge-name, $pill-badge-color in (primary, var(--theme-deafult)),
  (secondary, $secondary-color), (success, $success-color),
  (danger, $danger-color), (info, $info-color), (light, $light-color),
  (dark, $dark-color), (warning, $warning-color)
{
  .pill-badge-#{$pill-badge-name} {
    background-color: $pill-badge-color;
    box-shadow: -0.5px 4.33px 16px 0px rgba($pill-badge-color, 0.25);
    color: $white;
    border-radius: 0;
  }
}
.pill-badge-primary {
  background-color: var(--theme-deafult);
}
.pill-badge-secondary {
  background-color: var(--theme-secondary);
}
// round badge loop css
@each $round-badge-name, $round-badge-color in (primary, var(--theme-deafult)),
  (secondary, $secondary-color), (success, $success-color),
  (danger, $danger-color), (info, $info-color), (light, $light-color),
  (dark, $dark-color), (warning, $warning-color)
{
  .round-badge-#{$round-badge-name} {
    border-radius: 6px;
    background-color: $round-badge-color;
    box-shadow: -0.5px -6.67px 14px 0px rgba($round-badge-color, 0.18);
    color: $white !important;
  }
}
.round-badge-primary {
  background-color: var(--theme-deafult);
}
.round-badge-secondary {
  background-color: var(--theme-secondary);
}
.badges-example-sec {
  .card-body {
    h6 {
      margin-bottom: 0;
    }
  }
}
/**=====================
    49. Badge CSS Ends
==========================**/