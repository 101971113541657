/**=====================
     14. Chat CSS Start
==========================**/
.chat-box {  
  .chat-menu{
    width:320px;
    .people-list{
      .search{
        i{
          top: 16px;
        }
      }
    }
  }
  .people-list {
    .search{
      position: relative;
      .form-control{       
        border: 1px solid $light-gray;
        &::placeholder{
            color: $semi-dark;
        }
      }
      i {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 14px;
        color: $light-gray;
      }
    }
    ul {
      padding: 0;
      li {
        padding-bottom: 20px;
        &:last-child{
          padding-bottom: 0;
        }
      }
    }
  }
  .user-image {
    float: left;
    width: 52px;
    height: 52px;
    margin-right : 5px;
  }
  .about {
    float:left;
    margin-top:5px;
    padding-left:10px;
    .name {
      color: $dark-color;
      letter-spacing: 1px;
      font-weight: 600;
    }
  }
  .status {
    color: $light-text;
    letter-spacing:1px;
    font-size: 12px;
    margin-top: 5px;
    .chat-status{
      font-weight:600;
      color: $theme-body-font-color;
    }
    p{
      font-size:14px;
    }
  }
  .chat-right-aside{
    .chat {
      .chat-header{
        padding:15px;       
        border-bottom:1px solid $light-gray ;           
        img {
          float:left;
          width:50px;         
          box-shadow:1px 1px 4px 1px $light-gray;
        }
        .chat-menu-icons{
          margin-top:15px;
          margin-left:auto;         
          display:flex;
          justify-content:flex-end;
          li{
            a i{
              color: $theme-body-sub-title-color;
              font-size: 25px;
              cursor: pointer;
            }
          }
        }
      }
      
      .chat-msg-box {
        padding: 20px;
        overflow-y: auto;
        height: 560px;
        margin-bottom: 90px;
        .chat-user-img{
          margin-top: -35px;
        }
        .message-data {
          margin-bottom: 10px;
        }
        .message-data-time {
          letter-spacing: 1px;
          font-size: 12px;
          color:$semi-dark;
          font-weight:600;
        }
        .message {
          color: $light-text;
          padding: 20px;
          line-height: 1.9;
          letter-spacing: 1px;
          font-size: 14px;
          margin-bottom: 30px;
          width: 50%;
          position: relative;
        }
        .my-message {
          border:1px solid $light-gray;
          border-radius: 10px;
          border-top-left-radius: 0;
        }
        .other-message {
          border:1px solid $light-gray;
          border-radius: 10px;
          border-top-right-radius: 0;
        }
      }
      .chat-message {
        padding: 20px;
        border-top: 1px solid $light-body-bg-color;
        position: absolute;
        width: 100%;
        bottom: 0;
        .smiley-box{
          background: $light-semi-gray;
          padding: 10px;
          display: block;
          border-radius: 4px;
          margin-right: 0.5rem;
        }
        .text-box{
          position: relative;
          .input-txt-bx {
            height: 50px;
            border: 2px solid $primary-color;
            padding-left: 18px;
            font-size: 12px;
            letter-spacing: 1px;
          }
          i{
            position: absolute;
            right: 20px;
            top: 20px;
            font-size: 20px;
            color: $light-gray;
            cursor: pointer;
          }
          .btn{
            font-size: 16px;
            font-weight: 500;               
          }
        }
      }
    }
  }
  .chat-menu {   
    right:10px;   
    opacity:0;
    transform:translateY(-30px);
    visibility:hidden;
    top:95px;
    position:absolute;
    z-index: 7;
    background-color: $white;
    border: 1px solid $light-gray;
    transition: all linear 0.3s;
    padding-right: unset;
    
    .tab-pane {
      padding: 0 15px;
    }
    ul{
      li{
        .about{
          .status{
            i{
              font-size: 10px;
            }
          }
        }
      }
    }
    .user-profile {
      margin-top: 30px;
      .user-content{
        h5{
          margin:15px 0;
          font-weight:600;
        }       
        p{
          font-size:14px;
        }
        .social-list{
          li{
            display: inline-block;
            a{
              width:30px;
              height:30px;
              border-radius:5px;
              background-color: rgba($primary-color,0.08);
              color: $primary-color;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            &:hover{
              a{
                background-color:$primary-color;
                color:$white;
              }
            }
            &:nth-child(n+2){
              margin-left:5px;
            }
          }
        }       
      }
      .image {
        position: relative;
        .icon-wrapper {
          position:absolute;
          bottom:0;
          left:55%;
          display:flex;
          justify-content:center;
          align-items:center;
          height:30px;
          width:30px;
          border-radius:50%;
          background-color: $white;
          cursor:pointer;
          overflow:hidden;
          margin:0 auto;
          font-size:14px;
          box-shadow:1px 1px 3px 1px $light-gray;
          i{
            color:$primary-color;
          }
        }
        .avatar  {
          width:80px;
          height:80px;
          border-radius:50%;
          overflow:hidden;
          margin-left:auto;
          margin-right:auto;
          img{
            width:100%;
            height:100%;
            border-radius: 50%;
            border: 5px solid $light-gray;
          }
        }
      }     
      .border-right {
        border-right: 1px solid $light-gray;
      }
      .follow {
        margin-top:15px;
        padding-top:15px;
        border-top:1px solid $light-gray ;
        .follow-num {
          font-size:20px;
          font-weight:500;
          line-height:1;
        }
        span {
          color: $light-text;
          font-size: 14px;
          letter-spacing: 1px;
          font-weight:600;
        } 
      }   
      .digits{
        border-top:1px solid $light-gray;
        margin-top:15px;
        padding-top:15px;
        p{
          margin-bottom:unset;
        }
      }   
      .social-media a{
        color: $semi-dark;
        font-size: 15px;
        padding: 0 7px;
      }
      .chat-profile-contact{
        p{
          font-size:14px;
          color:$light-text;
        }
      }
    }
    .nav{
      margin-bottom: 20px;
    }
    .nav-tabs .nav-item {
      width:33.33%;
      a {
        padding: 15px!important;
        color: $semi-dark!important;
        letter-spacing: 1px;
        font-size: 14px;
        font-weight: 600;       
        display:flex;
        align-items:center;
        justify-content:center;
      }
      .material-border{
        border-width:1px;
        border-color:$primary-color;
      }
      .nav-link.active {
        color: $primary-color !important;
      }
    }
    &.show {
      opacity: 1;
      visibility: visible;
      transform: translateY(0px);
      transition: all linear 0.3s;
      padding-bottom: 25px;
      position: absolute;
      top: 75px;
    }
  }
  .chat-history{
     .call-content {
       display: flex;
       align-items: center;
       justify-content: center;
       min-width:300px;
       min-height:655px;
       >div{
         width:100%;         
         padding:30px;        
       }
     }
     .total-time h2 {
       font-size: 50px;       
       font-weight: 700;
       margin-bottom: 30px;
       color: $light-text;
     }
     .receiver-img{
       margin-top: 55px;
       img{
          border-radius: 5px;
       }
     }
     .call-icons{
       margin-bottom: 35px;
       ul{
         display: flex;
         align-items:center;
         justify-content:center;
         li {
           width: 55px;
           height: 55px;
           border: 1px solid $light-gray;
           border-radius: 50%;          
           display:flex;
           align-items:center;
           justify-content:center;
           + li{
             margin-left: 10px;
           }
           a {
             color: #999;
             font-size: 22px;
             line-height: 1;
           }
         }
       }
     }
     .caller-img{
       height:100%;
       border-radius: 0 0 15px 0;
     }
   }
}
.chat-left-aside{
  > .media{
    margin-bottom: 15px;
  }
  .people-list{
    height: 625px;
    .form-group{
      i{
        &.fa-search:before {
          content: "";
          position: relative;
          top: 1px;
      }
      }
    }
  }
  ul li{
    position: relative;
  }
}
.status-circle{
  width: 12px;
  height: 12px;
  position: absolute;
  top:4px;
  left:44px;
  border-radius: 50%;
  border:2px solid $white;
}
.away{
  background-color: $warning-color;
}
.online{
  background-color: $success-color;
}
.offline{
  background-color: $danger-color;
}
.chat-container{
  .aside-chat-left{
    width:320px;
  }
  .chat-right-aside{
    width:320px;
  }
}
.call-chat-sidebar{
  max-width: 320px;
}
.call-chat-sidebar,.chat-body{
    .card{
        .card-body{
            padding: 15px;
            margin-bottom: 15px;
        }
    }
}



.chart-widget{
  .chart-widget-top{
    h4{
      font-weight:600;
    }
    .num{
      i{
        margin-left:5px;
      }
    }    
  }
}
.apexcharts-menu{ 
  border-color: $light-gray !important;
}
@media(max-width:1660px){
  .chat-history{
    .call-content {
      min-width:300px;
      min-height:480px;
      >div{
        width:100%;         
        padding:30px;        
      }
    }
  }


}
@media(max-width:1366px){
  .chat-box{
    .chat-right-aside{
      .chat{
        .chat-message{
          bottom:0;
        }
      }
    }
  }
}
@media(max-width:360px){
  .chat-box{
    .chat-menu{
      width:220px;      
    }
  }
}





@media(max-width:360px){
.chat-right-aside{
  .chat {
    .chat-header{
      padding:15px;       
      border-bottom:1px solid $light-gray ;           
      img {
        float:left;
        width:50px;         
        box-shadow:1px 1px 4px 1px $light-gray;
      }
      .chat-menu-icons{
        margin-top:15px;
        margin-left:auto;         
        display:flex;
        justify-content:flex-end;
        li{
          margin-right:unset;
          a i{
            color: $theme-body-sub-title-color;
            font-size: 25px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
}

/**=====================
    14.  Chat CSS Ends
==========================**/