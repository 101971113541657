/* You can add global styles to this file, and also import other style files */


@import "assets/scss/app.scss";

@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900;1000&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css');

*:not(i) {
    // font-family: 'Cairo', sans-serif !important;
    font-family: 'Cairo', sans-serif;
}
html, body {
    overflow: visible !important;
    overflow-x: hidden !important;
  }
@font-face {
    font-family: 'DM Sans';
    src: url('../src/assets/fonts/Cairo-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

//   src: url('../assets/fonts/dm-sans-regular.ttf') format('truetype');

::-webkit-scrollbar {
    width: 7px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #aaa;
}

::-webkit-scrollbar-thumb {
    background-color: #aaa;
    border-radius: 20%;
}

::-webkit-scrollbar-thumb {
    background-color: #aaa;
    border-radius: 20px;
    border: 1px solid transparent;
    background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #8d9da0;
}







.tab-content .tab-pane {
    opacity: 1 !important;
}

.product-table .dataTables_wrapper button {
    padding: 2px 12px !important;
}



.table>caption+thead>tr:first-child>th,
.table>colgroup+thead>tr:first-child>th,
.table>thead:first-child>tr:first-child>th,
.table>caption+thead>tr:first-child>td,
.table>colgroup+thead>tr:first-child>td,
.table>thead:first-child>tr:first-child>td {
    border-top: 1px solid #ddd !important;
}

.table>thead>tr>th {
    vertical-align: middle !important;
}

.col-xs-3 {
    float: none !important;
}

.ng-select .ng-select-container {
    min-height: 42px !important;
}

.ng-select {
    height: 50px !important;
}

.form-control,
.form-select {
    font-size: 14px !important;

    // padding: 8px !important;

}

.form-file {
    font-size: 18px !important;
    padding: 5px !important;
    height: 38px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
    top: 10px !important;
}

.owl-dt-container {
    font-size: 1.6rem !important;
}


.fade {
    opacity: 1;
    transition: opacity .15s linear;
    -webkit-transition: opacity .15s linear;
    -moz-transition: opacity .15s linear;
    -ms-transition: opacity .15s linear;
    -o-transition: opacity .15s linear;

}





.form-control,
.form-select {
    font-size: 14px !important;
}


.tab-content .tab-pane {
    opacity: 1 !important;
}



.table>caption+thead>tr:first-child>th,
.table>colgroup+thead>tr:first-child>th,
.table>thead:first-child>tr:first-child>th,
.table>caption+thead>tr:first-child>td,
.table>colgroup+thead>tr:first-child>td,
.table>thead:first-child>tr:first-child>td {
    border-top: 1px solid #ddd !important;
}

.table>thead>tr>th {
    vertical-align: middle !important;
}

.col-xs-3 {
    float: none !important;
}

.ng-select .ng-select-container {
    min-height: 42px !important;
}

.ng-select {
    height: 50px !important;
}

.form-file {
    font-size: 16px !important;
    padding: 5px !important;
    height: 38px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
    top: 10px !important;
}

.owl-dt-container {
    font-size: 1.6rem !important;

}

.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
    float: none !important;
    margin-left: 0 !important;
}

.radio,
.checkbox {
    display: block;
    min-height: 20px;
    padding-left: 0 !important;
    margin-top: 10px;
    margin-bottom: 10px;
}



html[dir="ltr"] {
    .navbar-brand {
        position: relative;
    }

    .navbar-brand::after {
        content: "";
        position: absolute;
        height: 43px;
        width: 2px;
        background-color: #E6E8EC;
        border-radius: 2px;
        right: -12px;
        top: 0;
    }
}

// growth auth

:root {
    --eyas-color-gold: #cda551;
    --eyas-color-main: #1E3C50;
    --eyas-color-dark-blue: #1e3c50;
    --eyas-color-gray: #B9BFC3;
    --eyas-color-gray-dark: #C6D4DF;
    --eyas-color-light: #F4F5F5;
    --eyas-color-White: #FFFCF2;

    --eyas-color-gold-99: #cda55199;
    --eyas-color-gold-disabled: #cda55177;
    --eyas-color-gold-bb: #cda551bb;
    --eyas-color-gold-dark: #bc9440;
    --eyas-color-success: #1BC236;
    --eyas-color-dark: #141416;
    --eyas-color-light-blue: #5da6db;
    --eyas-color-secondary: #7C7C7C;
 
}

.text-gold {
    color: var(--eyas-color-gold);
}

.text-eyas-dark {
    color: var(--eyas-color-dark);
}

.text-eyas-secondary {
    color: var(--eyas-color-secondary);
}

.text-main {
    color: var(--eyas-color-main);
}

.text-eyas-success {
    color: var(--eyas-color-success);
}

.bg-gold {
    background-color: var(--eyas-color-gold);
}

.btn.btn-eyas-gold {
    background-color: var(--eyas-color-gold);
    color: #fff;

    &:active,
    &:focus,
    &:hover {
        color: #fff;
        background-color: var(--eyas-color-gold-dark);
        box-shadow: 0 0 2px 0px var(--eyas-color-gold);
    }

    &:disabled,
    &:disabled:hover,
    &:disabled:active {
        color: #fff;
        background-color: var(--eyas-color-gold-disabled);
        box-shadow: none;
    }
}

.input-group.input-group-eyas-gold:has(.form-control:focus) {
    outline: 0;
    box-shadow: 0 0 0 0.25rem var(--eyas-color-gold-disabled);

    >* {
        outline: 0;
        box-shadow: none !important;
        border-color: var(--eyas-color-gold-99);
    }
}

.form-control.form-control-eyas-gold:focus {
    outline: 0;
    border-color: var(--eyas-color-gold-99);
    box-shadow: 0 0 0 0.25rem var(--eyas-color-gold-disabled);
}

.container-eyas *::selection {
    background-color: var(--eyas-color-gold-bb);
    color: #fff;
}

.btn-eyas-outline-gold {

    &:active,
    &:focus,
    &:hover {
        border-color: var(--eyas-color-gold) !important;
        background-color: var(--eyas-color-gold-dark) !important;
        box-shadow: 0 0 2px 0px var(--eyas-color-gold) !important;

        &,
        &>* {
            color: #fff !important;
        }
    }


    &:disabled,
    &:disabled:hover,
    &:disabled:active {
        border-color: var(--eyas-color-gold-disabled) !important;
        box-shadow: none !important;
    }
}

.link-eyas-gold {

    &,
    &:visited {
        color: var(--eyas-color-gold);
        text-decoration: none;
    }

    &:active,
    &:focus,
    &:hover {
        color: var(--eyas-color-gold-dark) !important;
        text-shadow: 0 0 2px 0px var(--eyas-color-gold) !important;
    }
}

.input-group.input-group-eyas {
    border-radius: .375rem;

    &>* {
        background-color: #fff;
        border-width: 1px;
        border-radius: 0;
    }

    &> :first-child {
        border-left-width: 0;
        border-top-right-radius: .375rem !important;
        border-bottom-right-radius: .375rem !important;
    }

    &> :last-child {
        border-right-width: 0;
        border-top-left-radius: .375rem !important;
        border-bottom-left-radius: .375rem !important;
    }
}
@media (max-width:600px) {
    .owlLawyers .owl-nav .owl-prev {
       margin-left: 70px;
   }
   .owlLawyers .owl-nav .owl-next {
        margin-right: 70px;
    }
    .prveiw{
        width: 140px !important;
        height: 120px !important;
        min-width: 140px !important;
        min-height: 120px !important;
    }
}
ngx-dropzone-remove-badge{
    opacity: 1 !important;
}

.owl1 .owl-nav.disabled {
    display: block !important;
}

.owl-theme .owl-nav {
    position: absolute;
    height: auto;
    width: 100%;
    top: 50%;
    margin-top: 0 !important;
    text-align: center;
    // z-index: -2;
    -webkit-tap-highlight-color: transparent;
}

.owl1 .owl-nav .owl-prev {
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    position: absolute;
    bottom: 40%;
    left: -50px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;

    background: #CDA551;
    color: #fff;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);

}

.owl1 .owl-nav .owl-next,
.owl1 .owl-dot {
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    position: absolute;
    bottom: 40%;
    right: -50px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    width: 40px;
    height: 40px;

    background: #CDA551;
    color: #fff;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);

}

.owl1 .owl-nav .owl-prev,
.owl1 .owl-nav .owl-next {
    font-size: 18px;
}

.owl1 .owl-nav .owl-prev:hover,
.owl1 .owl-nav .owl-next:hover {
    background: #CDA551;
}

.owl2 .owl-nav.disabled {
    display: block !important;
}

@media (min-width:768px) {
    .owl2 .owl-nav {
        display: none !important;
    }
}
.black-arrow{
    width: 15px;
  }
@media (max-width:767px) {


    .owl2 .owl-nav .owl-prev {
        cursor: pointer;
        -webkit-user-select: none;
        user-select: none;
        position: absolute;
        bottom: -210px;
        background: transparent !important;
        right: 33px;
        width: 40px;
        height: 40px;
        color: black;
    }

    .owl2 .owl-nav .owl-next {
        cursor: pointer;
        -webkit-user-select: none;
        user-select: none;
        position: absolute;
        bottom: -210px;
        background: transparent !important;
        right: 0;
        width: 40px;
        height: 40px;
        color: black;
    }
}

.owl2 .owl-nav .owl-prev:hover,
.owl2 .owl-nav .owl-next:hover {
    color: black;
}

.owl3 .owl-nav .owl-prev {
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    position: absolute;
    bottom: 50%;
    background: transparent !important;
    left: -35px;
    width: 40px;
    height: 40px;
    color: #fff;
}

.owl3 .owl-nav .owl-next {
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    position: absolute;
    bottom: 50%;
    background: transparent !important;
    right: -35px;
    width: 40px;
    height: 40px;
    color: #fff;
}
.legalOwl .owl-theme .owl-dots {
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {

    background: rgba(205, 165, 81, 1) !important;

}
.owl-theme .owl-dots .owl-dot span{
    background: #F4F5F5 !important;
}
.radio-primary input[type=radio]+label::before {
    border-color: #1E3C50 !important;
}

.radio-primary input[type=radio]+label::after {
    background-color: #1E3C50 !important;
}
@media screen and (max-width: 1660px) and (min-width: 1200px)
{
    .owl-carousel .owl-item img {
        margin: 0 !important;
    }
}
p{
    letter-spacing: 0;
}
.icon-home {
    font-size: 18px;
}